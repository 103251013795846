import classNames from "classnames";
import {useSelector} from "react-redux";

import {getPageTemplates} from "~/reducers/page";

import * as Pages from "~/components/pages";

import classes from "./Page.module.css";
import {classPrefix, createStylesSelector, isNumber} from "~/lib";
import {PageComponent, PageProps} from "~/@types/components/templates/PageProps";

export default function Page({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: PageProps) {
    const pageTemplates = useSelector(getPageTemplates);
    let PageComponent: null | PageComponent;
    if (pageTemplates.length) {
        PageComponent = pageTemplates.reduce<typeof PageComponent>(
            (CurrentPageComponent: typeof PageComponent, tpl) => {
                if (isNumber(tpl)) {
                    tpl = "_" + tpl;
                }
                if (!CurrentPageComponent && Pages[tpl]) {
                    CurrentPageComponent = Pages[tpl];
                }
                return CurrentPageComponent;
            },
            null
        ) || Pages.Default;
    } else {
        PageComponent = null;
    }

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return PageComponent ? <PageComponent
        {...props}
        className={classNames(classPrefix("page"), propsClassName, styles("page"))}
        styles={styles}
    /> : null;

}